// Be careful with order of stlyes!
import 'normalize.css';
import 'antd/dist/reset.css';
import './index.css';
import React              from 'react';
import ReactDOM           from 'react-dom/client';
import reportWebVitals    from './reportWebVitals';
import { ConfigProvider } from 'antd';
import es_ES              from 'antd/lib/locale/es_ES';
import moment             from 'moment';
import { AppRouter }      from './router/AppRouter';
import { AuthProvider }   from './auth/AuthContext';

// Config language ESP
moment.locale('es');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
      <ConfigProvider locale={es_ES}>
        <AuthProvider>
          <AppRouter/>
        </AuthProvider>
      </ConfigProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
