import { createContext, useEffect, useState } from "react";
import { ServicesLogin }                      from "../services/ServicesLogin";
import { SessionStorage }                     from "../utils/SessionStorage";

// Constants
export const AuthContext = createContext({});
/**
 * status can be status: "checking" | "not-authenticated" | "authenticated" | "error"
 * logged can be true or false
 * errorMessage is a string
 * 
 * 
 * This case is exceptional. We calculate if user is authenticated or not
 * by hold the same page current when user reload web page
 */
const AuthStateInitial = {
  status: "not-authenticated",
  logged: SessionStorage.getItem("user_info")? true : false,
  errorMessage: ""
}

export const AuthProvider = ({ children }) => {
  ////////
  // Hooks
  ////////
  const [authState, setAuthState] = useState(AuthStateInitial);

  useEffect(() => {
    // We keep sesion user according to localStorage
    if(SessionStorage.getItem("user_authenticated")){
        setAuthState({status: "authenticated", logged: true});
    }
  }, []);

  /**
   * 
   * @param {object} values 
   */
  const login = async(values) => {
    // Active spinner
    setAuthState(({ status: "checking", logged: false}));
    const resp = await ServicesLogin(values);
    // Handle error
    if(resp.error){
      setAuthState(({ status: "error", logged: false, errorMessage: "Ha habido un error. Por favor contacte con el administrador" }));
    }else{
      // Only to test in development mode
      /*resp.menu = [
        { 
          path: "/siga",
          name: "Siga"
        },
        { 
          path: "/hospital",
          name: "Hospital",
          subMenu: [
            {
              path: "/hospital/detalle",
              name: "Detalle"
            },
            {
              path: "/hospital/estadistica",
              name: "Estadística"
            },
            {
              path: "/hospital/administracion",
              name: "Administración"
            },
          ]
        }
      ];*/
      // Fin test

      SessionStorage.setItem("user_info", resp);
      setAuthState(({ status: "authenticated", logged: true}));
    }
  }

  const logout = () => {
    SessionStorage.removeAll();
    setAuthState({ status: "not-authenticated", logged: false });
  }

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        login,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}