import { Button, Col, Divider, Form, Input, message, Modal, Row, Typography } from "antd";
import { useEffect, useState }    from "react";
import { ServicesHospital }       from "../services/ServicesHospital";
import {
  DeleteOutlined,
  PlusOutlined
} from '@ant-design/icons';

// Constants
const { Title } = Typography;
const { Search } = Input;
const stateInitial = {
  colas: [],
  openModalDeletePhone: false,
  phoneToDelete: {department: "", phone: ""}
}

export const HospitalAdministracion = () => {
  ////////
  // Hooks
  ////////
  const [state, setState] = useState(stateInitial);
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    // Update title
    document.title = "Icot - Hospital administración";

    getData();
  }, []);
  
  ////////////
  // Functions
  ////////////
  const getData = async() => {
    const resp = await ServicesHospital.getAdministration();
    // Update form
    form.setFieldsValue({
      tiempo_agente: resp.tiempo_agente,
      tiempo_maximo: resp.tiempo_maximo,
    });
    // Update state
    setState(prevState => ({ ...prevState,
      colas: resp.colas
    }));

  }

  const sendData = async(values) => {
    let checkValidation = true;
    // Validation colas
    state.colas.forEach(section => {
      Object.values(section)[0].forEach(phone => {
        if(validatePhone(phone) === "error") checkValidation = false;
      });
    });
    if(checkValidation === false) return;

    // Prepare data
    const payload = {
      tiempo_agente: values.tiempo_agente,
      tiempo_maximo: values.tiempo_maximo,
      colas: state.colas
    }
    const resp = await ServicesHospital.editAdministration(payload);
    openMessage ();
  }

  const openMessage = () => {
    messageApi.open({
      type: 'success',
      content: 'Se ha editado con éxito',
    });
  };

  const addInput = (section) => {
    const newColas = state.colas;
    newColas.forEach( (item, index) => {
      if(Object.keys(item)[0] === Object.keys(section)[0]){
        let newArray = Object.values(item)[0];
        newArray.push("");
      }
    });

    setState(prevState => ({
      ...prevState,
      newColas
    }));
  }

  const handleOnChange = (section, phone, event) => {
    const value = event.target.value
    const newColas = state.colas;
    newColas.forEach( (item, indexDepartment) => {
      if(Object.keys(item)[0] === Object.keys(section)[0]){
        let foundDepartment = Object.values(item)[0];
        let indexPhoneFound = 0;
        // Find position of number
        foundDepartment.forEach( (phoneItem, indexPhone) => {
          if(phoneItem === phone){
            indexPhoneFound = indexPhone;
          }
        });

        // Delete found phone
        foundDepartment[indexPhoneFound] = value;
      }
    });
    setState(prevState => ({
      ...prevState,
      newColas
    }));
  }

  const handleOpenModalDeletePhone = (section, item) => {
    setState(prevState => ({
      ...prevState,
      openModalDeletePhone: true,
      phoneToDelete: {department: Object.keys(section)[0], phone: item}
    }));
  }

  const cancelElimination = () => {
    setState(prevState => ({
      ...prevState,
      openModalDeletePhone: false
    }));
  }

  const deletePhone = async() => {
    const newColas = state.colas;
    newColas.forEach( (item, indexDepartment) => {
      if(Object.keys(item)[0] === state.phoneToDelete.department){
        let foundDepartment = Object.values(item)[0];
        let indexPhoneFound = 0;
        // Find position of number
        foundDepartment.forEach( (phone, indexPhone) => {
          if(phone === state.phoneToDelete.phone){
            indexPhoneFound = indexPhone;
          }
        });

        // Delete found phone
        foundDepartment.splice(indexPhoneFound, 1);
      }
    });
    setState(prevState => ({
      ...prevState,
      newColas
    }));
    cancelElimination();
  }

  /**
   * Validation phone
   * @returns {string} validation It can be "error" | "" 
   */
  const validatePhone = (phone) => {
    // Validation
    if(phone !== "" && phone.length <= 9 && (phone.charAt(0) === "6" || phone.charAt(0) === "7"
    || phone.charAt(0) === "8" || phone.charAt(0) === "9" || phone.charAt(0) === "")){
      return "";
    }
    return "error";
  }

  return (
    <div>
      {contextHolder}
      <div className="ycs-card" style={{ margin: 10 }}>
        {/* Manage by useForm */}
        <Form
          name="administracion"
          form={form}
          labelCol={{ md: 10, lg: 8, xxl: 4 }}
          wrapperCol={{ md: 4, lg: 4, xxl: 4 }}
          labelAlign="left"
          onFinish={sendData}
        >
          <Form.Item
            label="Tiempo de espera por agente (seg)"
            name="tiempo_agente"
            rules={[{ required: true, message:"Campo requerido", type: 'any' }]}
          >
            <Input type="number"/>
          </Form.Item>
          <Form.Item
            label="Tiempo de espera máximo (seg)"
            name="tiempo_maximo"
            rules={[{ required: true, message:"Campo requerido", type: 'any' }]}
          >
            <Input type="number"/>
          </Form.Item>

          {/* Manage by state of component */}
          <Title level={4}>Colas:</Title>
          <div style={{ margin: "0 0 20px 50px" }}>
            {state.colas?.map( section => (
              <Row style={{ marginBottom: 20 }}>
                <Col span={24}>
                  {Object.keys(section)}
                </Col>
                <Col>
                  <Row gutter={[16, 16]}>
                    {Object.values(section)[0].map(item => (
                      <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
                        <Form>
                          <Form.Item
                            validateStatus={validatePhone(item)}
                            help={validatePhone(item) === "error" ?
                            `Los números de teléfono deben empezar por 6 ó 7 ó 8 ó 9 y 
                            tener una longitud menor o igual a 9 caractéres` : ''}
                          >
                            <Search
                              value={item}
                              onChange={event => handleOnChange(section, item, event)}
                              enterButton={<Button onClick={() => handleOpenModalDeletePhone(section, item)} type="primary" danger icon={<DeleteOutlined twoToneColor="#EB2F2F"/>}></Button>}
                            />
                          </Form.Item>
                        </Form>
                      </Col>
                    ))}
                    <Col xs={24} sm={12} md={8} lg={6} xxl={4} style={{ marginLeft: 30 }}>
                      <Button onClick={() => addInput(section)} type="primary" icon={<PlusOutlined />} />
                    </Col>
                  </Row>
                </Col>
                <Divider/>
              </Row>
            ))}
          </div>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Editar
            </Button>
          </Form.Item>
        </Form>
      </div>

      {/* Modals
      Modal to delete a phone */}
      <Modal
        title="Alerta"
        open={state.openModalDeletePhone}
        onOk={deletePhone}
        onCancel={cancelElimination}
        footer={[
          <Button type="primary" onClick={cancelElimination}>
            Cancelar
          </Button>,
          <Button type="primary" danger onClick={deletePhone}>
            Eliminar
          </Button>,
        ]}
      >
        <p>¿Seguro que desea eliminar el número {state.phoneToDelete?.phone} del
         departamento {state.phoneToDelete?.department}?</p>
      </Modal>
    </div>
  )
}
