export const typeInfo = [
 {name:"RADIOLOGIA", items:["RADIOLOGIA","MENU_1"], color:"#e79632"},
 {name:"NEUROLOGIA", items:["NEUROLOGIA","MENU_2"], color:"#3264e7"},
 {name:"ESPECIALIDADES", items:["ESPECIALIDADES","MENU_3"], color:"#ca1917"},
 {name:"HOSPITALIZACION", items:["HOSPITALIZACION","MENU_4_1"], color:"#29b507"},
 {name:"RECEPCION", items:["RECEPCION","MENU_4_2"], color:"#b5079a"},
 {name:"SIN_DATOS", items:["null"], color:"#020202"},
] 

export const getFormatedItem = (nombre) => {
  
  const item = typeInfo.find(a => a.items.indexOf(nombre)>=0)

  return {
    nombre: nombre === "null" ? "SIN_DATOS" : nombre,
    color: item ? item.color : getRandomColor(),
    name: item ? item.name : nombre
  }
}

const getRandomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}