import CryptoJS  from "crypto-js";

const cifrate = process.env.REACT_APP_CIFRATE_STORAGE === "true"? true : false;
const wordSecret = process.env.REACT_APP_WORD_SECRET;

export class SessionStorage{
    /**
     * 
     * @param {string} key 
     * @returns 
     */
    static getItem(key){
        if(cifrate){
            const keyEncrypted = sessionStorage.getItem( findWordSecret[key] );
            return keyEncrypted? JSON.parse( CryptoJS.AES.decrypt(keyEncrypted, wordSecret).toString(CryptoJS.enc.Utf8) ): null;
        }else{
            return JSON.parse( sessionStorage.getItem(key) );
        }
    }

    /**
     * 
     * @param {string} key 
     * @param {any} value 
     */
    static setItem(key, value){
        if(cifrate){
            sessionStorage.setItem(findWordSecret[key], CryptoJS.AES.encrypt( JSON.stringify(value), wordSecret).toString());
        }else{
            sessionStorage.setItem(key, JSON.stringify(value));
        }
    }

    /**
     * 
     * @param {string} key 
     */
    static removeItem(key){
        if(cifrate){
            sessionStorage.removeItem(findWordSecret[key]);
        }else{
            sessionStorage.removeItem(key);
        }
    }

    static removeAll(){
        sessionStorage.clear();
    }
}

// All keys are always string's, each property has 10 characters
const findWordSecret = {
    user_info: "J0JgPoK5)_"
}