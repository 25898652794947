import { useContext, useEffect } from "react";
import { AuthContext }          from "../auth/AuthContext";
import { Alert, Button, Input, Row, Col, Card, Form } from 'antd';

export const LoginPage = () => {
  ////////
  // Hooks
  ////////
  const {status, login, errorMessage} = useContext(AuthContext);
  
  useEffect(() => {
    // Update title
    document.title = "Icot - Login";
  }, []);


  ////////////
  // Functions
  ////////////
  const onFinish = (values) => {
    login(values);
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="App">
      <Row>
          <Col offset={7} span={8}>
              <Card 
                style={{borderWidth:'5px'}}
                title={<Row>
                        <Col span={7}><img src='logo-web.png' width={'50px'}></img></Col>
                        <Col span={17}><h1 style={{position:'absolute'}}></h1></Col>
                      </Row>}>
                    
                <Form name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  style={{ maxWidth: 600 }}
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item label="Usuario" name="usuario"
                    rules={[{ required: true, message: 'Introduzca un usuario!' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item label="Contraseña" name="password"
                    rules={[{ required: true, message: 'Introduzca la contraseña!' }]}
                  >
                    <Input.Password />
                  </Form.Item>

                  {status === "error" && <Alert message={errorMessage} type="error" showIcon style={{ marginBottom: 20 }}  />}


                  <Form.Item wrapperCol={{ offset: 15, span: 5 }}>
                    <Button type="primary" htmlType="submit">
                      Aceptar
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
          </Col>      
      </Row>
    </div>
  );
}