import { useContext, useEffect, useState }  from "react";
import { AuthContext }                      from "../auth/AuthContext";
import { Navigate }                         from "react-router-dom";
import { SessionStorage }                   from "../utils/SessionStorage";


export const ProtectedRoute = ({ path, children }) => {
  ////////
  // Hooks
  ////////
  const {logged} = useContext(AuthContext);

  const [state, setState] = useState({ logged, path: "", state: "" });
  useEffect(() => {
    // Check authorization
    if(logged){
      const user = SessionStorage.getItem("user_info");
      if(user?.menu?.length > 0){
        // Previously unroll completed menu, it’s more easy to work
        const newMenu = [];
        user.menu.map(menu => {
          if(menu.subMenu?.length > 0){
            menu.subMenu.forEach(subMenu => {
              newMenu.push(subMenu);
            });
          }else{
            newMenu.push(menu);
          }
        });

        // Find menu according to newMenu
        const found = newMenu.find(item => item.path === path);
        if(found){
          setState({ logged: true, path: found.path, found: "found" });
        }else{
          setState({ logged: true, path: newMenu[0].path, state: "notFound" });
        }
      }else{
        setState({ logged: true, path: "/without-menu" });
      }
    }
  }, [logged, path]);

  if(!logged) {
    return <Navigate to="/login"/>
  }else if(path !== state.path && state.state === "found"){
    return <Navigate to={path}/>
  }else if(path !== state.path && state.state === "notFound"){
    return <Navigate to={state.path}/>
  }else{
    return (
      <>
        {children}
      </>
    )
  }
}
