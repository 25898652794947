
/*
* Component OficinasDetalle
 * @returns 
 */
export const OficinasDetalle = () => {

    return(
        <div>
            <p> Componente Oficinas Detalle</p>
        </div>
    )
}