import 'antd/dist/reset.css';
import locale from 'antd/es/date-picker/locale/es_ES';
import React, { useEffect, useRef, useState } from 'react';
import { Space, Table, Alert, Button, Input, Switch, Popconfirm, Row, Col, Card, Avatar, Form, Tag, Image, DatePicker  } from 'antd';
import { SearchOutlined, CheckCircleOutlined, EyeOutlined, EyeInvisibleOutlined, UserOutlined } from '@ant-design/icons';
import Highlighter                            from 'react-highlight-words';
import moment                                 from "moment";
import dayjs                                  from "dayjs";

const API_URL = process.env.REACT_APP_API_URL_SIGA;

export const Siga = () => {

  ////////
  // Hooks
  ////////
  const [cases, setCases] = useState([]);
  const [caseVisible, setCaseVisible] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const handleSearch = (
    selectedKeys, confirm, dataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  
  const getColumnSearchProps = (dataIndex, dataText) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Buscar ${dataText}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Resetear
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys)[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtrar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      return record[dataIndex] 
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value).toLowerCase())
        : false;
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const case_columns = [
    {
      title: ' ',
      dataIndex: 'estado',
      key: 'actions',
      render: (value, all) => <Popconfirm
        title="¿Quiere cambiar de estado la tarea?"
        onConfirm={() => {
          fetch(API_URL+"api/cases/"+all.id, {
                method: 'PUT',
                headers: {'Content-Type': 'application/json'}              
          })
          .then((response) => getCases())
        }}
        onCancel={() => {}}
        okText="Si"
        cancelText="No"
      >
        {value 
        ? <Button shape="circle" icon={<EyeInvisibleOutlined />} />
        : <Button shape="circle" icon={<EyeOutlined />} />
        }
      </Popconfirm>
    },
    {
      title: 'Fecha',
      dataIndex: 'inicio',
      key: 'inicio',
      sorter: (a, b) => moment(a.inicio) > moment(b.inicio) ? 1 : -1,
      sortOrder: 'descend',
      render: (value) => moment(new Date(value.substring(0,22))).format("YYYY-MM-DD HH:mm")
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      key: 'tipo',
      render: (value) =>  <Tag color={value?.includes('URGENCIAS_ICOT') ? "#FF0000" : 
                                      value?.includes('URGENCIAS_') ? "blue" : 
                                      value=='NO_URGENCIA' ? "green" : 
                                      value=='URGENCIA_NO_CLIENTE' ? "" : 
                                      value?.includes('URGENCIA') ? "error" : 
                                      ""}>{value}</Tag>,
      filters: [
        {text: 'URGENCIAS', value: 'URGENCIAS',},
        {text: 'NO_URGENCIA', value: 'NO_URGENCIA'},
        {text: 'URGENCIA_NO_CLIENTE', value: 'URGENCIA_NO_CLIENTE'},
        {text: 'URGENCIAS_ICOT', value: 'URGENCIAS_ICOT'}        
      ],
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) => record.tipo?.includes(value),
    },
    {
      title: 'Teléfono',
      dataIndex: 'user_id',
      key: 'user_id',
      ...getColumnSearchProps('user_id','teléfono'),
    },


    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      ...getColumnSearchProps('nombre','nombre'),
    },
    {
      title: 'Dirección',
      dataIndex: 'direccion',
      key: 'direccion',
      ...getColumnSearchProps('direccion','dirección'),
    },
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      ...getColumnSearchProps('descripcion','descripción'),
    },
    {
      title: 'Tratamiento',
      dataIndex: 'tratamiento',
      key: 'tratamiento',
      render: (value) =>  value ? <CheckCircleOutlined /> : "",
      filters: [
        {text: 'SI', value: true,},
        {text: 'NO', value: false},
      ],
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) => record.tratamiento == value,
    },
    {
      title: 'Autocita',
      dataIndex: 'autocita',
      key: 'autocita',
      render: (value) =>  value ? <CheckCircleOutlined /> : "",
      filters: [
        {text: 'SI', value: true,},
        {text: 'NO', value: false},
      ],
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) => record.autocita == value,
    },
  ];

  useEffect(() => {
    // Update title
    document.title = "Icot - SIGA";

    getCases({ fecha: null, estado: true });
  }, []);

  const getCases = async(values) => {
    setCases([]);
    let url  =API_URL+"api/cases";
    url +="?estado="+values.estado
    if (values.fecha){
      url += "&fecha="+values.fecha.format("YYYY-MM-DD");
    }
    fetch(url)
    .then((response) => response.json())  
    .then((cases) => setCases(cases));      
  }

  return (
  <div>
    <Space direction="vertical" size="middle" style={{ display: 'flex', margin:'10px' }}>
      {caseVisible && <Alert type="info" message={<b>Tareas</b>} closable afterClose={() => setCaseVisible(false)}
        description={
          <>
            <p>Lista de tareas creadas por el sistema telefónico automático. Puede aplicar los siguinetes filtros para buscar tareas.</p>
            <p>Las tareas las puede ocultar una vez procesadas para mayor facilidad en la gestión.
            En función del contenido de la descripción del usuario la llamada se clasifica con los siguientes tipos:
            </p>
            <ul>
              <li style={{marginTop:'5px'}}><Tag color="green">NO_URGENCIA</Tag> En las llamada NO_URGENCIA se le dice al paciente que le contactaremos en menos de 48 horas.</li>
              <li style={{marginTop:'5px'}}><Tag color="">URGENCIA_NO_CLIENTE</Tag> Llamadas Urgentes que no están en tratamiento y se indica que llame a compañía.</li> 
              <li style={{marginTop:'5px'}}><Tag color="#FF0000">URGENCIAS_ICOT</Tag> Llamadas pasadas al departamento de Operativa de ICOT.</li>
              <li style={{marginTop:'5px'}}><Tag color="blue">URGENCIAS_ISLA</Tag> Llamadas derivadas a Hospital por fuera de horario.</li>
            </ul>
            <div style={{position: 'absolute', right: '10px', bottom: '10px'}}>
                <Image width={100} src="flujo_siga.png" />
            </div>
          </>
        } />}
      <Row>
        <Col span={20}>
          <Space wrap>
            <Form name="filters"
              layout='horizontal'
              // We use library dayjs only to this special and rare case
              // due to Ant Design
              initialValues={{ estado: true, fecha: null }}
              onFinish={(values) => {
                getCases(values);
              }}
            >
              <Space style={{ display: 'flex', marginTop: 8 }} >
                <Form.Item label="Fecha" name="fecha">
                  <DatePicker locale={locale}/>
                </Form.Item>
                <Form.Item label="Estado" name="estado">
                  <Switch 
                    checkedChildren={<EyeOutlined />}
                    unCheckedChildren={<EyeInvisibleOutlined />}
                    defaultChecked
                  />
                </Form.Item>
                <Form.Item >
                  <Button type="primary" htmlType="submit" style={{marginLeft:'25px'}}>
                    Filtrar
                  </Button>
                </Form.Item>
                </Space>
            </Form>
            
          </Space>
        </Col>
        <Col span={4} style={{textAlign:'end'}}>
          {!caseVisible && <Button  type="link" onClick={() => {setCaseVisible(true)}}>Info</Button>}
        </Col>
      </Row>
    </Space>

    <div style={{ backgroundColor: "#FFF", overflow: "auto" }}>
      <Table dataSource={cases} columns={case_columns} bordered pagination={false}/>
    </div>
  </div>
)
}