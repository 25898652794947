import { useContext, useEffect, useState }       from "react";
import { Navigate }         from "react-router-dom";
import { AuthContext }      from "../auth/AuthContext";
import { SessionStorage } from "../utils/SessionStorage";

/**
 * This case is exceptional. We calculate if user is authenticated or not
 * by hold the same page current when user reload web page
 */
let authenticatedAux = false;
if(SessionStorage.getItem("user_info")){
  authenticatedAux = true;
}

export const ProtectedAnyRoute = () => {
  ////////
  // Hooks
  ////////
  const {logged} = useContext(AuthContext);
  const [state, setState] = useState({ logged: authenticatedAux, path: "" });

  useEffect(() => {
    // Check authorization
    if(logged){
      const user = SessionStorage.getItem("user_info");
      if(user?.menu?.length > 0){
        // Go to first element by default 
        setState({ logged: true, path: user.menu[0].path});
      }else{
        setState({ logged: true, path: "/without-menu" });
      }
    }
  }, [logged]);
  
  if(!logged) {
    return <Navigate to ="/login"/>
  }else if(state.path){
    return <Navigate to={state.path}/>
  }
}
