import './MenuPage.css';
import React, { useContext, useEffect, useState }         from 'react';
import { Button, Dropdown, Layout, Menu, Space }          from 'antd';
import { useCalculateWindow }                             from '../../hooks/useCalculateWindow';
import { AuthContext }                                    from '../../auth/AuthContext';
import { NavLink, Outlet, useLocation }                   from 'react-router-dom';
import { SessionStorage }                                 from '../../utils/SessionStorage';
import {
  AppstoreOutlined,
  DatabaseOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined
} from '@ant-design/icons';

// Constants
const { Header, Sider, Content } = Layout;
const MenuPageInitalState = {
  collapsed: false,     // false = open SiderBar, true = close SiderBar
  itemsMenu: [],
  currentPath: "",
  currentMenuOpen: "",
}
const breakSizeScreen = 480;        // Hide or show sidebar according to with screen 480px

// Functions
function getItem(
  label,
  key,
  icon,
  children,
) {
  return {
    key,
    icon,
    children,
    label,
  };
}

/**
 * Menu with navbar, sidebar and content
 * @returns Component React
 */
export const MenuPage = () => {

  ////////
  // Hooks
  ////////
  const {logout} = useContext(AuthContext);
  const [state, setState] = useState(MenuPageInitalState);
  const {innerWidth} = useCalculateWindow();
  const location = useLocation();


  /**
   * Building menu sidebar
   */
  useEffect(() => {
    const user_info = SessionStorage.getItem("user_info");
    const itemsMenu = [];

    // Build menu
    if(user_info?.menu?.length > 0){
      user_info.menu.map(item => {
        if(item.path === "/siga"){
          itemsMenu.push(getItem(<NavLink to={item.path} onClick={() => updateItemSelected(item.path)}>Siga</NavLink>, item.path, <DatabaseOutlined />));
        }else if(item.path === "/hospital"){
          // Add subMenu
          if(item.subMenu?.length > 0){
            const auxSubMenu = [];
            item.subMenu.map(i => {
              auxSubMenu.push(getItem(<NavLink to={i.path} onClick={() => updateItemSelected(i.path)}>{i.name}</NavLink>, i.path));
            });
            itemsMenu.push(getItem("Hospital", item.path, <AppstoreOutlined />, auxSubMenu));
          }else{
            itemsMenu.push(getItem("Hospital", item.path));
          }
        }else if(item.path === "/oficinas"){
          // Add subMenu
          if(item.subMenu?.length > 0){
            const auxSubMenu = [];
            item.subMenu.map(i => {
              auxSubMenu.push(getItem(<NavLink to={i.path} onClick={() => updateItemSelected(i.path)}>{i.name}</NavLink>, i.path));
            });
            itemsMenu.push(getItem("Oficinas", item.path, <AppstoreOutlined />, auxSubMenu));
          }else{
            itemsMenu.push(getItem("Oficinas", item.path));
          }
      }
      });
    }

    // Update state
    setState(prevState => ({
      ...prevState,
      itemsMenu,
    }));
  }, []);

  // Open menu according to url
  useEffect(() => {
    const currentPath = location.pathname;
    // Show current menu open in sidebar
    const currentMenuOpen = "/" + currentPath.split("/").slice(1, 2);

    // Update state
    setState(prevState => ({
      ...prevState,
      currentMenuOpen,
    }));
  }, []);

  // Select current menu
  useEffect(() => {
    // Show current menu or subMenu in sidebar
    const currentPath = location.pathname;

    // Update state
    setState(prevState => ({
      ...prevState,
      currentPath,
    }));
  }, [location]);

  // Handle width menu SideBar
  useEffect(() => {
    setState(prevState => ({
        ...prevState,
        collapsed: innerWidth < breakSizeScreen? true:false
    }));
  }, [innerWidth]);

  ////////////
  // Functions
  ////////////
  /**
   * Handle onClick button open or close sidebar
   */
  const handleMoveMenuSideBarWithButton = () => {
    setState(prevState => ({
        ...prevState,
        collapsed: !prevState.collapsed
    }));
  }

  /**
   * Menu of perfil’s user in navbar
   */
  const menuPerfilUser = {
    items: [
    {
      key: 1,
      label: (
        <a>
          Ver perfil
        </a>
      )
    },
    {
      key: 2,
      label: (
        <a style={{ color: "#FF4D4F" }} onClick={logout}>
          Cerrar sesión
        </a>
      )
    }
  ]}

  const updateItemSelected = (path) => {
    // Update state
    setState(prevState => ({
      ...prevState,
      currentPath: path
    }));
  }

  return (
    <Layout style={{ minHeight: '100vh' }} hasSider>
      <Sider trigger={null} collapsible collapsed={state.collapsed} className="ycs-sider" style={{ backgroundColor: "#000", position: "fixed", top: 0, left: 0, bottom: 0, overflow: 'auto' }}>
        <div className="ycs-sideBarLogo">
            <NavLink to="/">
                <img
                    src={ state.collapsed? process.env.PUBLIC_URL+"/logo-web.png": process.env.PUBLIC_URL+"/logo-web.png"} alt="icot-logo" height={state.collapsed? "35px":"40px"}
                    style={{ cursor: "pointer" }}
                />
            </NavLink>
        </div>

        {/* Sidebar */}
        <div className="ycs-container-sidebar">
          {/* It’s a special case of Ant Design */}
          {state.currentMenuOpen  && (
            <Menu
              theme="dark"
              mode="inline"
              items={state.itemsMenu}
              selectedKeys={[ state.currentPath ]}  // Only work on componentDidMount
              defaultOpenKeys={[ state.currentMenuOpen ]}
            />
          )}

          {/* Version */}
          <Menu
            theme='dark'
            selectable={false}
            style={{ backgroundColor: "#000" }}
            items={[ { label: state.collapsed? <img src="/logo.png" height={30}/> : <img src="/logo_letras_blanco.png" height={30}/>  } ]}
          />
        </div>
   
      </Sider>

      <Layout className="site-layout" style={{ marginLeft: state.collapsed? "80px":"200px", transition: "0.2s" }}>
        <Header className="site-layout-background"
            style={{
                alignItems: "center",
                boxShadow: "rgb(0 0 0 / 8%) 0 1px 0",
                display: "flex",
                justifyContent: "space-between",
                left: 0,
                marginLeft: state.collapsed? "80px":"200px",
                padding: 0,
                position: "fixed",
                right: 0,
                transition: "0.2s",
                width: state.collapsed? "calc(100vw - 80px)":"calc(100vw - 200px)",
                zIndex: 30
            }}
        >
            <Space>
                {React.createElement(state.collapsed? MenuUnfoldOutlined : MenuFoldOutlined, {
                    className: 'trigger',
                    onClick: handleMoveMenuSideBarWithButton,
                })}
            </Space>

            {/* Button user options */}
            <Dropdown menu={menuPerfilUser} trigger={['click' ]}>
              <Button style={{ marginRight: "25px", border: "none", boxShadow: "none" }}>
                {SessionStorage.getItem("user_info")?.user}
                <UserOutlined style={{ fontSize: 20 }} />
              </Button>
            </Dropdown>
        </Header>

        <Content
            // className="site-layout-background"
            style={{
                // minHeight: "calc(100vh - 64px)",
                margin: '68px 8px 8px 8px',
            }}
        >
            <Outlet/>
        </Content>
      </Layout>
    </Layout>
  );
}