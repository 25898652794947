import './HospitalEstadistica.css';
import React, { useEffect, useState }   from 'react';
import { Button, Col, DatePicker, Form, Row, Space, Typography, Table } from 'antd';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, 
  XAxis, YAxis } from "recharts";
import {DownloadOutlined} from '@ant-design/icons';
import dayjs                            from "dayjs";
import moment                           from 'moment';
import { ServicesHospital }             from '../../services/ServicesHospital';
import _                                from 'lodash';
import { getFormatedItem }               from '../../utils/Utils';

// Constants
const { Title } = Typography;
const stateInitial = {
  typeCalls: [],
  uniqueTypeCalls: [],
  repetitionsCalls: [],
  kpi:{},
  kpi_total:[],
  kpi_privadas:[],
  kpi_campania:[],
  inicio: "",
  final: ""
}

  
  
export const HospitalEstadistica = () => {
  ////////
  // Hooks
  ////////
  const [state, setState] = useState(stateInitial);
  
  useEffect(() => {
    // Update title
    document.title = "Icot - Hospital estadística";    
    getData({ start: moment().set("date",1), fin: moment() });
  }, []);

  const getData = async(values) => {
    const start = values.start.format("YYYY-MM-DD");
    const fin = values.fin.format("YYYY-MM-DD");
    setState(prevState => (
      {
        ...prevState,
        inicio: start,
        final: fin,
        kpi_total:[],
        kpi_privadas:[],
        kpi_campania:[],
      }
    ));
    getTypeCalls(start, fin);
    getDataTotales(start, fin);
    getDataPrivadas(start, fin);
    getDataCampania(start, fin);
  }
  const getDatos = async() => {
    await ServicesHospital.getDatos(state.inicio, state.final);
  }
  const getDataTotales = async(start,fin) => {
    const kpi_total = await ServicesHospital.getLlamadasTotales(start, fin);
    // Update state
    setState(prevState => (
      {
        ...prevState,
        kpi_total
      }
    ));
  }
  const getDataPrivadas = async(start,fin) => {
    const kpi_privadas = await ServicesHospital.getLlamadasPrivadas(start, fin);
    // Update state
    setState(prevState => (
      {
        ...prevState,
        kpi_privadas
      }
    ));
  }
  const getDataCampania = async(start,fin) => {
    const kpi_campania = await ServicesHospital.getLlamadasCampania(start, fin);
    // Update state
    setState(prevState => (
      {
        ...prevState,
        kpi_campania
      }
    ));
  }
  const getTypeCalls = async(start, fin) => {
    const resp = await ServicesHospital.getTypeCalls(start, fin);
    const aux = [], uniqueTypeCalls = [], typeCalls = [];
    // 1º Search unique values
    resp.forEach(i => {
      i.opciones.forEach(o => {
        aux.push(o);
      })
    });
    const unrollArray = _.groupBy(aux, "nombre");
    
    // 2º Fill color
    _.map(unrollArray, (value, key) => {
      uniqueTypeCalls.push(getFormatedItem(key))
    });

    // 3ª Fill values to show in graph
    resp.forEach(i => {
      const aux_2 = {};
      aux_2.dia = i.dia;
      i.opciones.forEach(o => {
        let property = o.nombre === null ? "SIN_DATOS" : o.nombre;
        aux_2[property] = o.total;
      });
      typeCalls.push(aux_2);
    });

    // Update state
    setState(prevState => (
      {
        ...prevState,
        typeCalls,
        uniqueTypeCalls
      }
    ));
  }
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload.length > 0 && payload.length) {
      return (
        <div className='ycs-container-tooltip'>
          <p style={{ fontWeight: "bold" }}>{label}</p>
          {payload.map(i => (
            <p style={{ color: i.fill }}>{i.dataKey}: {i.value}</p>
          ))}
        </div>
      );
    }
    return null;
  };

  const columnsTotales = [
    {
      title: 'Llamadas',
      dataIndex: 'todas',
      key: 'todas',
      render: (text, record, index) => (
        <>
        <Typography.Title level={2} style={{ margin: 0 }}>
        {['Totales','Privado','Campaña'][index]}
        </Typography.Title>
      </>
      ),
    },
    {
      title: 'Llamadas',
      dataIndex: 'todas',
      key: 'todas',
      align: 'center',
      render: (text, record, index) => (
        <>
          <Typography.Title level={2} style={{ margin: 0 }}>
          {text}
          </Typography.Title>
          {index>0 &&
            <Typography.Title level={5} style={{ margin: 0 }}>
            {((text/state.kpi_total.todas)*100).toFixed(2)} %
            </Typography.Title>
          }
        </>
      ),
    },
    {
      title: 'Respondidas',
      dataIndex: 'respondidas',
      key: 'respondidas',
      align: 'center',
      render: (text, record, index) => (
        <>
          <Typography.Title level={2} style={{ margin: 0, color: 'green' }}>
          {text}
          </Typography.Title>
          <Typography.Title level={5} style={{ margin: 0, color: 'green' }}>
          {((text/record.todas)*100).toFixed(2)} %
          </Typography.Title>
        </>
      ),
    },
    {
      title: 'Perdidas',
      dataIndex: 'perdidas',
      key: 'perdidas',
      align: 'center',
      render: (text, record, index) => (
        <>
          <Typography.Title level={2} style={{ margin: 0, color: 'red' }}>
          {text}
          </Typography.Title>
          <Typography.Title level={5} style={{ margin: 0, color: 'red' }}>
          {((text/record.todas)*100).toFixed(2)} %
          </Typography.Title>
        </>
      ),
    },
    {
      title: 'Gestionadas',
      dataIndex: 'gestionadas',
      key: 'gestionadas',
      align: 'center',
      render: (text, record, index) => (
        <>
          <Typography.Title level={2} style={{ margin: 0, color: 'green' }}>
          {text}
          </Typography.Title>
          <Typography.Title level={5} style={{ margin: 0, color: 'green' }}>
          {((text/record.perdidas)*100).toFixed(2)} %
          </Typography.Title>
        </>
      ),
    },
    {
      title: 'Sin Gestionar',
      dataIndex: 'nogestionadas',
      key: 'nogestionadas',
      align: 'center',
      render: (text, record, index) => (
        <>
          <Typography.Title level={2} style={{ margin: 0, color: 'red' }}>
          {text}
          </Typography.Title>
          <Typography.Title level={5} style={{ margin: 0, color: 'red' }}>
          {((text/record.perdidas)*100).toFixed(2)} %
          </Typography.Title>
        </>
      ),
    },
    {
      title: 'T. respuesta',
      dataIndex: 'respuesta',
      key: 'respuesta',
      align: 'center',
      render: (text, record, index) => (
        <>
          <Typography.Title level={2} style={{ margin: 0}}>
          {text==0 && record.menos24>0 ? 0 : text && text>0 ? (text*1).toFixed(0) : "-"} horas
          </Typography.Title>
          <Typography.Title level={5} style={{ margin: 0}}>
          {record.menos24 >0 ? ((record.menos24/record.gestionadas)*100).toFixed(2) : 0} % en 24h.
          </Typography.Title>
        </>
      ),
    },
    {
      title: 'Citas',
      dataIndex: 'citas',
      key: 'citas',
      align: 'center',
      render: (text, record, index) => (
        <>
          <Typography.Title level={2} style={{ margin: 0 }}>
          {text}
          </Typography.Title>
          <Typography.Title level={5} style={{ margin: 0 }}>
          {record.gestionadas>0 ?((text/record.gestionadas)*100).toFixed(2) : 0} %
          </Typography.Title>
        </>
      )
    },
    {
      title: 'Privado',
      dataIndex: 'privado',
      key: 'privado',
      align: 'center',
      render: (text, record, index) => (
        <>
          <Typography.Title level={2} style={{ margin: 0 }}>
          {text}
          </Typography.Title>
          <Typography.Title level={5} style={{ margin: 0 }}>
          {record.citas>0 ? ((text/record.citas)*100).toFixed(2):0} %
          </Typography.Title>
        </>
      ),
    },
    {
      title: 'Compañìa',
      dataIndex: 'compania',
      key: 'compania',
      align: 'center',
      render: (text, record, index) => (
        <>
          <Typography.Title level={2} style={{ margin: 0 }}>
          {text}
          </Typography.Title>
          <Typography.Title level={5} style={{ margin: 0 }}>
          {record.citas>0 ? ((text/record.citas)*100).toFixed(2) :0} %
          </Typography.Title>
        </>
      ),
    },
  ];

  return (
    <div>
      {/* Filters */}
      <Row>
        <Col span={24}>
          <Form name="filters"
            layout='horizontal'
            // We use library dayjs only to this special and rare case
            // due to Ant Design
            initialValues={{ start: dayjs().set("date",1), fin: dayjs() }}
            onFinish={(values) => {
              getData(values);
            }}
          >
            <Space style={{ display: 'flex', marginTop: 8 }} >
              <Form.Item label="Inicio" name="start"
                rules={[{required: true, message:"Fecha de inicio requerida", type: 'object' }]}
              >
                <DatePicker format={"DD/MM/YYYY"}/>
              </Form.Item>
              <Form.Item label="Fin" name="fin"
                rules={[{required: true, message:"Fecha de fin requerida", type: 'object' }]}
              >
                <DatePicker format={"DD/MM/YYYY"}/>
              </Form.Item>
              <Form.Item >
                <Button type="primary" htmlType="submit" style={{marginLeft:'25px'}}>
                  Filtrar
                </Button>
              </Form.Item>
              </Space>
          </Form>
        </Col>
      </Row>

      <div className='ycs-container-llamadas-atendidas'>
          <div className='ycs-title-llamadas-concurrentes'>
            <Title strong level={4}>
              Llamadas totales
            </Title>
          </div>
          <Table pagination={false} 
                dataSource={[state.kpi_total, state.kpi_privadas, state.kpi_campania]} 
                columns={columnsTotales}
               />
      </div>


      {/* Graphs */}
      <br/>
      <div className='ycs-container-llamadas-concurrentes'>
        <div className='ycs-graph'>
          <div className='ycs-title-llamadas-concurrentes'>
            <Title strong level={4}>
              Llamadas por tipo
            </Title>
          </div>

          {state.typeCalls?.length > 0 ?
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={state.typeCalls}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="dia" />
                <YAxis />
                {/* <Tooltip /> */}
                <Tooltip content={<CustomTooltip /> }/>
                <Legend />
                {_.sortBy(state.uniqueTypeCalls, "name").map(i => (
                  <Bar dataKey={i.nombre} fill={i.color} key={i.nombre} stackId={i.name}/>
                ))}
              </BarChart>
            </ResponsiveContainer>
          :<EmptyGraph text="No hay llamadas por tipo"/> 
          }
        </div>
      </div>

      

      <Button type="primary" style={{float: 'right'}} 
        icon={<DownloadOutlined />}
        onClick={() => getDatos()}>
        Descargar
      </Button>


    </div>
  )
}

export const EmptyGraph = ({ text }) => {
  return (
    <div className='ycs-empty-graph'>
      {text}
    </div>
  );
}